// Socials

.socials {
    ul {
        margin: 10px 0 0 0;
        display: flex;
        justify-content: flex-start;
        list-style-type: none;

        a {
            color: $linkColor;
            transition: color .3s ease;
            font-size: 20px !important;

            &:hover {
                color: $carleRed;
                text-decoration: none;
            }
        }

        li {
            margin-top: 17px;

            & ~ li {
                margin-left: 20px;
                margin-top: 17px !important;
            }
        }
    }

    @media #{$mobile} {


        ul {
            justify-content: flex-start;

            li {
                margin-top: 14px;

                & ~ li {
                    margin-left: 20px;
                    margin-top:14px !important;
                }
            }
        }
    }
}
