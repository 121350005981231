// Number Oval

.number-oval {
	display: inline-block;
	width: 38px;
	height: 38px;
	line-height: 38px;
	text-align: center;
	font-weight: 900;
	font-size: 18px;
	background-color: $brand;
	border-radius: 50%;
	color: $white;

	@media #{$mobile} {
		width: 30px;
		height: 30px;
		font-size: 14px;
		line-height: 30px;
	}
}
