﻿@mixin carleButton() {
    display: inline-block;
    border: 2px solid transparent;
    background-color: transparent;
    color: white;
    transition: background-color .3s ease, color .3s ease;
    margin-bottom: pxToResponsiveUnit(5px);
    text-decoration: none;
    padding: pxToResponsiveUnit(12px) pxToResponsiveUnit(30px);
    font-size: pxToResponsiveUnit(14px);
    font-weight: 900;
}

@mixin featureTitle() {
    font-size: 1.125rem;
    font-weight: 900;
    color: $linkColor;
    margin-bottom: 1rem;
    line-height: 1rem;
}
@mixin featureAddress() {
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.375rem;
}
@mixin elevation() {
    background-color: white;
    box-shadow: 0 4px 17px 0 rgba(85,85,89,0.1);
}
@mixin tag($bg: $carleDarkBlue, $fg: $white) {
    color: $fg;
    background-color: $bg;
    text-transform: uppercase;
    text-align: center;
}

@mixin iconBg($w, $h, $url, $display: block) {
    display: $display;
    width: $w;
    height: $h;
    background: transparent url($url) no-repeat center / contain scroll;
}
