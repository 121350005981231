@import '_variables.scss';

// Header

.o-header {
    .m-header {
        &-bar {
        }

        &-inner {
            .location-links {
                &__link {
                    position: relative;

                    .a-link-panel {
                        @include elevation;
                        display: none;
                        width: 200%;
                        position: absolute;
                        z-index: 5;

                        @media (max-width: 1024px) {
                            width: 100%;
                        }

                        &__inner {
                            position: relative;
                            padding: 15px;
                            height: 600px;
                            overflow-y: scroll;
                        }

                        &__close {
                            i {
                                color: #555559;
                                font-size: 1.3rem;
                                font-weight: 400;
                            }
                        }
                    }

                    &--find-nearest-location {
                        .a-link-panel {
                            min-width: 290px;
                            right: 0;
                            width: 100%;

                            @media (max-width: 1380px) {
                                left: 0;
                                right: initial; 
                                width: 100%;
                            }

                            &__inner {
                                height: auto;
                            }

                            form {
                                margin-top: 10px;
                                padding-top: 10px;
                                border-top: 1px solid $maintextcolor;

                                div {
                                    position: relative;

                                    input {
                                        height: 35px;
                                        padding-right: 60px;
                                    }

                                    button {
                                        font-size: 14px;
                                        padding: 8px 14px;
                                    }
                                }
                            }
                        }
                    }

                    &--shortest-wait {
                        .address {
                            margin-top: 20px;
                            margin-bottom: 20px;
                        }

                        .a-btn-inverse {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.m-popover{
    .a-open-panel{
        color: $linkColor;
        cursor: pointer;
        font-weight:700;
        &:hover{
            color:$carleRed;
        }
    }
}