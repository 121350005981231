// Font Settings

.o-font-settings {
    position: relative;
    padding: 4px 8px 0 24px;
    margin: 0 0 0 15px;
    flex: 0 0 80px;
    max-width: 80px;
    text-align: right;
    display: none;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 1px;
        transform: translateY(-14px);
        background-color: $maintextcolor;
    }

    .font-size {
        text-transform: uppercase;
        color: $maintextcolor;
        font-size: 1rem;

        & ~ .font-size {
            margin-left: 14px;
            font-size: 1.4rem;
            font-weight: 700;
        }
    }

    @media #{$small-desktop} {
        margin: 0 0 0 10px;
    }

    @media #{$mobile} {
        padding: 0;

        &:before {
            display: none;
        }
    }
}
