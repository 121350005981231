// App

.a-app {
    &--bg {
        background-color: $grey;
    }

    &--elevated {
        @include elevation;
    }

    &__content {
        p {
            margin: 0;
        }
    }
    /* align-items: center;
    justify-content: space-between;
    padding: 40px 11px 45px 0;
    background-color: $grey;
    box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);
    .logo-small {
        position: absolute;
        top: 22px;
        left: 29px;
        transform: translateY(-50%);
    }

    p {
        margin: 0;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }
    .a-app__content {
        position: relative;
        padding: 0px 50px 0 100px;
    }

    .a-app__actions {
        flex: 0 0 305px;
        max-width: 305px;
    }

    @media #{$mobile} {
        display: block;
        padding: 30px 0px 30px 0;
        p {
            margin: 0 0 30px;
        }
        .a-app__content {
			padding: 0 30px 0 110px;
		}
        .a-app__actions {
            margin-left: 20px;

            img {
                width: 40%;
            }
        }
    } */
}