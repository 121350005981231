@import '../global/_variables.scss';

// Feature

.m-feature {
    margin: 0 0 33px;
    padding: 0 22px 0 81px;
    font-size: 1rem;
    color: $maintextcolor;


    ul {
        margin-left: 2.25rem;

        li {
            padding-bottom: 0.5rem;
        }
    }

    h2 {
        margin: 0 0 32px;
        font-family: $font-family-gfs-didot;
        font-size: 3rem;
        line-height: 1.1;
    }

    h4 {
        margin: 0 0 13px;
        font-size: 2rem;
    }

    p {
        margin: 0 0 24px;
        line-height: 1.5;
        font-size: 1rem;
    }

    .grid-x {
        &--revers {
            flex-direction: row-reverse;
        }
    }

    .a-feature-images {
        padding: 4px 26px 0 27px;

        .collage-area-mobile-alternative {
            display: none;
        }

        .grid-x {
            margin: 0 -8px;
        }

        .cell {
            padding: 0 8px;
        }
    }

    .e-feature-image {
        margin-bottom: 32px;

        &--large {
            padding: 32px 0 0;
        }
    }

    .a-feature-content {
        padding: 0 60px 0 0;
        line-height: 1.6;
    }

    .m-cards {
        margin: 0;
        padding: 64px 0 0;
    }

    @media #{$mobile} {
        margin: 0 0 0;
        padding: 0 0px 0;

        h2 {
            margin: 0 0 20px;
            font-family: $font-family-gfs-didot;
            font-size: 2rem;
        }

        h4 {
            margin: 0 0 10px;
            font-size: 1.6rem;
        }

        p {
            margin: 0 0 25px;
            line-height: 1.5;
        }

        .a-feature-content {
            padding: 10px 0px 0 0;
        }

        .a-feature-images {
            padding: 4px 0;

            .collage-area {
                display: none;
            }

            .collage-area-mobile-alternative {
                display: inline;
            }

            img {
                width: 505px;
            }
        }

        .e-feature-image {
            margin: 0 0 16px;

            img {
                width: 100%;
            }

            &--large {
                padding: 0 0 0;
            }
        }
    }
}

// Feature Media

.m-feature-media {
    position: relative;
    padding: 80px 0;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
    min-height: 345px;

    h2 {
        margin: 0 0 44px;
        font-family: $font-family-gfs-didot;
        font-size: 3rem;
        line-height: 1;
    }

    .m-feature-media__media {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
    }

    .m-feature-media__content {
        position: relative;
        padding: 24px 24px 72px;
        background-color: $white;

        &--alt {
            padding: 24px 24px 32px;

            .m-feature-media__actions {
                margin: 20px 0 0 0;
            }
        }
    }

    .a-btn-main, .a-btn-inverse {
        padding: 11px 54px;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 700;

        &--featured {
            padding: 11px 40px;
        }
    }




    .m-feature-media__actions {
        margin: 65px 0 0 -7px;
        display: table;
        width: 102%;

        .row {
            display: table-row;

            .button-cell {
                display: table-cell;
                width: 50%;

                .a-btn-main, .a-btn-inverse {
                    padding: 11px 40px;
                }

                &__divider {
                    width: 10%;
                }
            }
        }
    }

    .o-play {
        width: 160px;
        height: 160px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        left: 25%;
        top: 50%;
        margin-left: -80px;
        margin-top: -80px;
        z-index: 3;
        transition: opacity .4s ease;
        cursor: pointer;
        pointer-events: auto;
        border: 10px solid $white;

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 0 30px 50px;
            border-color: transparent transparent transparent $white;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -15px;
            margin-top: -25px;
            z-index: 4;
        }
    }

    &--form {
        font-size: 1rem;
        font-weight: 300;

        p {
            line-height: 1.25;
        }

        h2 {
            margin: 0 0 36px;
            font-family: $font-family-gfs-didot;
            font-size: 3rem;
            line-height: 1.04;
            font-weight: 400;
        }

        .m-feature-media__content {
            position: relative;
            padding: 55px 24px 30px;
            background-color: $white;

            &--alt {
                padding: 24px 24px 32px;

                .m-feature-media__actions {
                    margin: 20px 0 0 0;
                }
            }
        }
    }

    @media #{$small-desktop} {
        &--form {
            .large-4 {
                flex: 0 0 42%;
                max-width: 42%;
            }
        }

        .m-feature-media__actions {
            margin: 65px 0 0 -7px;
            display: table;

            .row {
                display: table-row;

                .button-cell {
                    display: table-cell;
                    width: 45%;

                    .a-btn-main, .a-btn-inverse {
                        padding: 11px 30px;
                    }

                    &__divider {
                        width: 10%;
                    }
                }
            }
        }
    }

    @media #{$mobile} {
        padding: 20px 0 0;

        h2 {
            margin: 0 0 20px;
            font-size: 2rem;
        }

        .o-play {
            display: none;
            left: 50%;
        }

        .m-feature-media__content {
            position: relative;
            padding: 12px .625rem;
            background-color: $white;
        }

        .m-feature-media__actions {
            margin: 15px 0;
            display: block;

            .row {
                display: block;

                .button-cell {
                    display: block;
                    width: 100%;

                    &__divider {
                        display: none;
                        width: 10%;
                    }
                }
            }


            a {
                display: block;
                width: 100%;
                margin: 0 0 10px;
                text-align: center;
            }
        }

        .m-feature-media__media {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            width: 100%;
            padding: 0 0 60%;
            background-size: cover;
            background-position: center center;
        }

        &--form {
            .large-4 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}

// Feature Donate

.m-feature-donate {
	h2 {
		margin: 0 0 39px;
	}

	.m-feature-donate__entry {
		padding: 17px 0 17px 45px;
	}

	.m-feature-donate__actions {
		padding: 30px 0 0;
	}

	@media #{$mobile} {
		h2 {
			margin: 0 0 15px;
		}

		.m-feature-donate__entry {
			padding: 25px 0;
		}

		.m-feature-donate__actions {
			padding: 15px 0 0;
		}
	}
}
