﻿/* ------------------------------------------------------------ *\
    Reset
\* ------------------------------------------------------------ */

* {
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: inherit;
    }
}

/* ------------------------------------------------------------ *\
    Base
\* ------------------------------------------------------------ */

body {
    color: $maintextcolor;
    font-size: 16px;
    line-height: 20px;
    font-family: $font-family-avenir;
    margin-top: 96px !important;

    .container {
        width: 70%;
    }

    @media all and (max-width: 1024px)  {
        margin-top: 123px !important;
    }

    @media #{$mobile}  {
        &.fixed {
            position: fixed;
            width: 100%;
            height: 100vh;
            overflow:hidden;
        }
    }
}

a {
    color: $linkColor;
    text-decoration: none;
    font-family: $font-family-avenir;

    &:hover {
        text-decoration: underline;
        color: $linkHoverColor;
    }
    &:focus {
        color: $linkColor;
        border: 0;
    }
}

a, button {
    &:focus {

        // background-color: $focusColor;
        outline: 2px solid $linkColor;
        outline-offset: 4px;

     }
}

b, strong {
    font-family: 'Avenir LT W01_85 Heavy1475544';
}

h1, h2, h3, h4, h5, h6 {
    font-family: linotype-didot-roman, serif;
    font-style: normal;
    font-weight: 400;
}

h1 {
    font-size: $h1Size;
    line-height: 1.1;

    @include breakpoint(large down) {
        font-size: 2rem;
    }
}

h2 {
    font-size: $h2Size;

    @include breakpoint(large down) {
        font-size: 2rem;
    }
}

h3 {
    font-size: $h3Size;
    line-height: 1.2;
}

h4 {
    font-size: $h4Size;
}

h5 {
    font-size: $h5Size;
}

h6 {
    font-size: $h6Size;
}

.h1, .h2, .h3, .h3-large, .h4, .h5, .h6 {
    font-family: linotype-didot-roman, serif;
    font-style: normal;
    font-weight: 400;
}

.h1 {
    font-size: $h1Size;
    line-height: 1.1;

    @include breakpoint(large down) {
        font-size: 2rem;
    }
}

.h2 {
    font-size: $h2Size;

    @include breakpoint(large down) {
        font-size: 2rem;
    }
}

.h3 {
    font-size: $h3Size;
    line-height: 1.2;
}

.h3-large {
    font-size: 2.0rem;
    line-height: 1.2;
    margin-bottom:16px;
}

.h4 {
    font-size: $h4Size;
}

.h5 {
    font-size: $h5Size;
}

.h6 {
    font-size: $h6Size;
}

.redtext {
    color: $carleRed;
}

.purple {
    background-color: $brandPurple;
}

.red {
    background-color: $brandRed;
}

.blue {
    background-color: $brandBlue;
}

.green {
    background-color: $brandGreen;
}

.giving, .family-handbook {
    .m-feature {
        .grid-x {
            border-bottom: 0 !important;
        }
    }
}

.video-iframe {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: block;
    background: black;
    position: relative;
    padding: 20px 40px;

    iframe {
        height: 500px;
        width: 100%;
        border: 0;

        @include breakpoint(medium down) {
            height: 450px;
        }

        @include breakpoint(small down) {
            height: 340px;
        }
    }

    a {
        color: white;
        text-align: center;
        width: 100%;
        display: block;
    }

    button.mfp-close {
        position: absolute;
        top: 0px;
        right: 0px;
        color: white;
        opacity: 1;
    }
}

ul {
    &.unstyled {
        list-style: none !important;

        li {
            &:before {
                content: none !important;
            }

            &:after {
                content: none !important;
            }
        }
    }

    &.styled {
        li {
            padding-bottom: 1rem;
        }
    }

    &.twocolumns {
        column-count: 2;

        @media #{$mobile} {
            column-count:1;
        }
    }
}

button {
    cursor: pointer;
}

button[disabled=disabled] {
    background-color: #ddd;
    color: #888;
    border-color: #ddd;

    &:hover {
        background-color:#ddd;
        color: #888;
        border-color: #ddd;
        cursor: not-allowed;
    }
}

.a-external-link:after {
    content: '\f08e';
    font-family: FontAwesome, sans-serif;
    margin-left: 5px;
}

a {
    font-weight: 900;
    // TODO: Uncomment this once content has been formatted appropriately and deemed safe
    // &[target=_blank]:after
    &.a-external-link[target=_blank]:after {
        content: '\f08e';
        font-family: FontAwesome, sans-serif;
        margin-left: 5px;
    }

    &.a-pdf-link[target=_blank]:before {
        content: '';
        @include iconBg(27px, 34px, '/getmedia/74c94670-d480-4e33-ab4c-3d83c9bb37ef/PDF-icon.png', inline-block);
        margin-right: 15px;
        vertical-align: middle;
    }
}

.center-card {
    margin: 0 auto 30px !important;
    max-width: 600px !important;
}

.media-selection-editor .col-lg-3.col-md-12 {
    min-width: 250px;
}

.media-selection-editor .media-gallery .col-md-6.mb-4 {
    min-width: 300px;
}

.media-selection-editor .col-lg-3.col-md-12 {
    min-width: 250px;
}

.pagination {
    &--ellipsis {
        .page-item {
            display: none !important;

            &--active {
                display: inline-block !important;
            }
        }
    }

    &--server {
        @include breakpoint(medium down) {
            li {
                display: inline-block !important;
            }
        }
    }
    // Same rule appears to exist in Foundation. Do we really need this?
}

.grey-bg {
    background-color: #fafafa;

    &.grid-container {
        margin-bottom: 44px;

        @include breakpoint(medium down) {
            margin-bottom: 24px;
        }
    }
}

.hide-except-mobile-tablet {
    display: none;

    @include breakpoint(large down) {
        display: block;
    }
}

.show-for-tablet-up {
    display: block;

    @media all and (max-width: 767px) {
        display: none;
    }
}

.hide-for-tablet-up {
    display: none;

    @media all and (max-width: 768px) {
        display: block;
    }
}

.noMarginTop {
    margin-top: 0;
}

.grid-container-small {
    max-width: 1080px !important;
}

a.pdf {
    position: relative;
    padding-left: 35px;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0 10px 35px;

    img {
        position: absolute;
        left: 0;
        top: 2px;
    }
}

.grid-container-smallest {
    max-width: 1006px !important;
}

.meet-our-team {
    max-width: 974px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@include breakpoint(medium down) {
    .grid-container.mobileNoPaddingX {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.grid-container.noPaddingX {
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include breakpoint(medium down) {
        padding-left: .625rem !important;
        padding-right: .625rem !important;

        &.mobileNoPaddingX {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.grid-container.offset-left-row {
    .large-offset-1 {
        margin-left: calc(5.1% + 0.9375rem);

        @include breakpoint(large down) {
            margin-left: 0;
        }
    }

    .large-5 {
        width: 40.78%;

        @include breakpoint(large down) {
            width: 100%;
        }
    }
}

.page-header {
    padding-top: 64px;
    margin-bottom: 60px;

    .a-btn-main {
        margin-top: 24px;
    }

    @include breakpoint(medium down) {
        padding-top: 24px;
        margin-bottom: 40px;
    }

    p {
        line-height: 1.4;
        margin: 48px 0;
    }

    .header-description {
        line-height: 1.4;

        @include breakpoint(medium down) {
            margin-top: 24px;
        }
    }
}

.page-hero {
    .large-12 {
        min-height: 400px;

        @include breakpoint(large down) {
            min-height: 250px;
        }

        @include breakpoint(small down) {
            min-height: 150px;
        }
    }
}

button.slick-arrow {
    font-size: 1.4rem;
    display: inline;
    position: relative;
    top: 2px;
}

button.slider-next.slick-arrow {
    margin-left: 10px;
}

button.slider-prev.slick-arrow {
    margin-right: 10px;
}

.slider-controls {
    text-align: center;
}

.slick-dots-wrap {
    position: relative;
    display: inline;
    text-align: center;
}

.slick-dots {
    display: inline !important;
    position: relative !important;
    bottom: 4px !important;

    li {
        button {
            &:before {
                border-top: 0 !important;
                content: "•" !important;
            }

            &[aria-selected="true"]::after {
                background-color: transparent !important;
                border: 0;
            }

            &[role="tab"]:hover::after {
                background-color: transparent !important;
                border: 0;
            }

            &[role="tab"]:focus::after {
                background-color: transparent !important;
                border: 0;
            }

            &[role="tab"][aria-selected="true"] {
                background-color: transparent !important;
            }
        }
    }
}

.pagination {
    a {
        @include breakpoint(medium down) {
            padding: 0.1875rem 4px;
        }
    }
}

.newsroom {
    .newsroom-categories {
        @include breakpoint(large down) {
            display: none;
        }
    }
    .tab-button-wrap {

        @include breakpoint(large down) {
            max-width: unset;
        }

        .tab-button {
            @include breakpoint(large down) {
                width: auto;
            }
        }
    }
}

.tab-button-wrap {
    text-align: center;
    position: relative;
    top: 30px;

    @include breakpoint(large down) {
        max-width: 500px;
        border: 1px solid #555559;
        border-radius: 7px;
        margin: 0 auto;
        min-height: 55px;
    }

    .tab-button {
        padding: 20px;
        border-top: 1px solid #555559;
        border-bottom: 1px solid #555559;
        color: $linkColor;
        font-weight: $global-weight-bold;
        position: relative;
        background-color: white;

        @include breakpoint(large down) {
            border: 0 !important;
            width: 33.33%;
            padding: 8px;
            display: block;
            height: 100%;
            float: left;
            line-height: 1.5;
            font-size: 0.9rem;
        }

        &.active {
            background-color: $linkColor;
            color: white;
        }

        &:hover {
            background-color: $linkColor;
            color: white;
            text-decoration: none;
        }

        &:first-child {
            border-left: 1px solid #555559;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }

        &:last-child {
            border-right: 1px solid #555559;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;

            &:after {
                content: none;
            }
        }

        &:after {
            content: '';
            height: 60%;
            position: absolute;
            width: 1px;
            background: #555559;
            top: 20%;
            right: 0;
        }
        &:focus {
            outline: 2px solid $linkColor;
            outline-offset: 4px;
            z-index: 999;
        }
    }
}



.tabbed-menu-wrap {
    .tabbed-menu {
        @include elevation;
        padding: 40px 24px 24px;

        .tab {
            display: none;

            &--active {
                display: block;
            }
        }

        .alpha-wrap {
            display: flex;
            flex: 0 0 100%;

            .alpha-box {
                width: 28px;
                color: #555559;
                height: 28px;
                line-height: 28px;
                text-align: center;
                display: inline-block;
                border: 1px solid #555559;
                margin: 0 1px;
                flex-grow: 1;

                &:hover {
                    background-color: $linkColor;
                    color: white;
                    text-decoration: none;
                }

                &.selected {
                    background-color: $linkColor;
                    color: white;
                }
            }
        }

        @include breakpoint(small down) {
            .alpha-wrap {
                display: block;
                flex: none;

                .alpha-box {
                    width: 9%;
                    flex-grow: unset;
                    margin: 2px 1% 2px 0;
                }
            }
        }
    }
}

.api-result {
    /*margin: 24px 0 0;*/
}

.negate-p p.section-header-subhead {
    margin-bottom: 24px;
    line-height: 1.6;
}

.section-header-subhead {
    width: 70%;
    margin: 24px auto;

    @include breakpoint(large down) {
        width: 100%;
    }
    
}

h2.section-header-w-subhead {
    text-align: center;
    margin: 64px 0 0;
}

.fifty-fifty-list {
    li {
        width: 46%;
        float: left;

        &:nth-child(odd) {
            margin-right: 8%
        }
    }
}

.section-header {
    &:after {
        content: '';
        display: block;
        width: 96px;
        height: 4px;
        background-color: $brandLightGreen;
        margin-top: 32px;
        margin-bottom: 48px;

        @include breakpoint(small down) {
            width: 65px;
            height: 2px;
            margin-top: 8px;
            margin-bottom: 24px;
        }
    }
}

.a-btn {
    @include carleButton;
    // TODO: Change &-main and &-inverse to &--main and &--inverse respectively
    // TODO: All references to '.a-btn-main' should be changed to '.a-btn .a-btn--main'
    // TODO: Create button mixins for size and color variants
    &-main {
        @include carleButton;
        border-color: $carleRed;
        background-color: $carleRed;
        color: white;
        font-weight: 900;

        &.play-btn {
            position: relative;

            i {
                position: absolute;
                left: 14px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                font-size: 10px;
            }
        }

        &:focus {
            background-color: $carleRed;
            outline: 2px solid $linkColor;
            outline-offset: 4px;
            color: white;
        }

        &:hover {
            background-color: $carleButtonHoverColor;
            color: white;
            text-decoration: none;
        }

        &--alt {
            padding: 11px 54px;
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 400;
            color: white !important;
        }

        @media #{$mobile-medium} {
            display: block;
            width: 100%;
            margin: 0 0 10px;
            text-align: center;
        }
    }

    &-inverse {
        @include carleButton;
        border-color: $carleRed;
        background-color: transparent;
        color: $carleRed;

        @include breakpoint(small down) {
            padding: 14px 30px 12px;
        }

        &:focus {
            background-color: transparent;
            outline: 2px solid $linkColor;
            outline-offset: 4px;
            color: $carleRed;
        }


        &:hover {
            background-color: white;
            color: $carleButtonHoverColor;
            border-color: $carleButtonHoverColor;
            text-decoration: none;
        }

        @media #{$mobile-medium} {
            display: block;
            width: 100%;
            margin: 0 0 10px;
            text-align: center;
        }
    }

    &--video {
        position: relative;

        &:before {
            position: absolute;
            left: 14px;
            top: 50%;
            content: '\f04b';
            font-family: FontAwesome;
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 10px;
        }
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--loading {
        &:after {
            @extend .fa-pulse;
            display: inline-block;
            content: '\f110';
            font-family: FontAwesome, sans-serif;
            margin-left: 5px;
        }
    }
}

.btn {
    color: blue;

        &--green {
            display: inline-block;
            padding: 10px 14px;
            line-height: 1;
            color: $white;
            background-color: $linkColor;
        }

        &.active {
            color: red;
        }

        &.test {
            background: red;
        }
    }

.o-breadcrumbs_main {
    .m-breadcrumbs-list-container {
        list-style: none;
        padding: 0;
        margin: 10px 0 32px 0;

        .a-breadcrumbs-item {
            font-weight: $global-weight-bold;
            margin: 0;
            padding: 0;
            display: inline-block;

            &:after {
                content: ".";
                display: inline-block;
                margin-right: 4px;
                margin-left: -4px;
            }
        }
    }
}

header {
    position: fixed;
    width: 100%;
    top: 0;
    border-bottom: 2px solid $darkRuleLine;
    z-index: 1000;
    background-color: $white;

    .grid-container {
        max-width: 78rem;
    }

    .m-header-bar {
        
        background-color: $brand;

        .grid-x {
            align-items: center;
        }

        a, button {
            &:focus {
                // border: 1px solid #fff !important;
                outline: 2px solid #fff;
                outline-offset: 4px;

                color: #fff !important;
            }
        }
    }

    .m-header-entry {

        .a-btn-main {
            padding: 4px 40px;
        }
    }

    .m-header-actions {
        display: flex;
        justify-content: flex-end;

        .a-btn-main {
            display: inline-block;
            margin-top: 0px !important; 
        }
    }

    .m-header-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .o-font-settings {
            display: none;
        }
    }

    @media #{$mobile} {
        .m-header-bar {
            display: none;
        }

        .m-header-entry {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            display: none;
            height: 100vh;
            padding: 0px 0 200px;
            text-align: center;
            background-color: $white;
            overflow: auto;

            .grid-x {
                display: block;
            }

            .cell {
                max-width: 100%;
            }
        }

        .m-header-content {
            padding-top: 13px;
            padding-bottom: 3px;
            flex-wrap: wrap;
            justify-content: space-between;

            .o-font-settings {
                display: block;
                order: 3;
                text-align: right;

                .font-size {
                    & ~ .font-size {
                        margin-left: 5px;
                    }
                }
            }

            .a-logo {
                order: 2;
            }

            .o-triggers {
                order: 1;
            }

            .a-btn-main {
                display: none;
            }

            .m-search {
                order: 4;
                display: block;
            }
        }

        .m-header-actions {
            padding-top: 10px;
            text-align: center;
            display: none;

            .a-btn-main {
                display: inline-block;

                .search {
                    display: none !important;
                }
            }
        }
    }
}


.hero-image {

    min-height: 344px;
    min-width: 1440px;

}

.header-main-image {

    height:312px;
    width: 488px;


}

    .grid-container-large {
        max-width: 1440px !important;
        overflow-x: hidden;
        min-height: 700px;
    }

    .slider-overflow {
        flex-flow: nowrap;
        align-items: center;
    }

.m-card_10 {
    background-color: white;
    box-shadow: 0 4px 17px 0 rgba(85,85,89,0.1);
    padding: 0;

    .cell.large-6 {
        padding: 40px 24px 10px;

        &.hide-for-small-only {
            padding: 0;
        }
    }

    .a-ul_3columns {
        .cell.large-6 {
            padding: 0px 0;
            font-weight: $global-weight-bold;
            min-height: 50px;
        }
    }

    @include breakpoint(medium down) {
        .grid-x > .large-6.hide-for-small-only {
            order: 1;
        }
    }
}

.m-card_9 {
    background-color: white;
    box-shadow: 0 4px 17px 0 rgba(85,85,89,0.1);

    &.grid-container {
        padding: 40px 24px 10px;
    }

    .a-ul_3columns {
        list-style: none;
        width: 100%;
        display: block;
        padding: 0;
        margin: 32px 0;

        .cell.large-4 {
            padding: 10px 0;
            font-weight: $global-weight-bold;
        }
    }
}

    .related-clinics-content {
        padding-bottom: 60px;

        h2 {
            text-align: center;
            margin: 72px 0 36px;
        }

        .large-3.cell {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .m-card_8 {
        background-color: white;
        box-shadow: 0 4px 17px 0 rgba(85,85,89,0.1);
        padding: 20px 12px 20px;
    }

    [v-cloak] {
        display: none;
    }

    @include breakpoint(large) {
        .grid-margin-x > .large-20 {
            width: calc(20% - 1.25rem);
        }

        .large-20 {
            @include xy-cell(1 of 5);
        }
    }

.shadow-box {
    box-shadow: 0 4px 17px 0 rgba(85,85,89,0.1);
    padding: 24px 24px 12px;
    background-color: white;
    margin: 20px 0;
}

    // Footer
.o-footer {
    box-shadow: 0 5px 8px 3px rgba(85,85,89,0.07);

    .a-logo {
        margin-top: 3px;
        width: 128px;
        height: 34px;
    }

    .m-open-chat {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 6px 5px;
        background-color: #037d96;
        text-align: center;
        z-index: 5;

        a {
            color: $white !important;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .a-footer__links {
        h4, h3, &--titles {
            margin: 0 0 13px;
            font-size: 1rem;
            line-height: 1.38;
            font-weight: 750;
            font-family: $font-family-avenir;
            display:inline-block;
            
        }

        ul {
            margin: 0;
            list-style-type: none;
            font-size: 0.75rem;
            font-weight: 900;
            line-height: 1.2;

            span {
                color: $maintextcolor;
                display: block;
            }

            li {
                & ~ li {
                    margin-top: 14px;
                }
            }

            a {
                font-size: 0.75rem;
                transition: color .3s ease;

                &:hover {
                    
                    text-decoration: underline;
                }
            }
        }
    }

    .m-footer__entry {
        position: relative;
        padding: 0 0px 42px 59px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: $maintextcolor;
        }
    }

    .a-footer__info {
        padding: 26px 50px 15px;
        text-align: center;
        font-size: 1rem;
        line-height: 1.5;

        a {
            font-weight: 900;
        }
    }

    .m-footer__bar {
        padding: 20px 0;
        box-shadow: 0 5px 8px 3px rgba(85,85,89,0.07);
        background-color: $brand;

        .grid-container {
            //max-width: 71rem;
        }

        .grid-x {
            align-items: center;
        }

        .m-footer__copyright {
            //margin-left: 13px;
            p {
                margin: 0;
                font-size: 0.875rem;
                color: $white;

                a {
                    text-decoration: underline;
                    color: $white;
                    font-size: 0.875rem;
                    transition: color .3s ease;
                    font-weight: 400;

                    &:hover {
                        color: $carleRed;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @media #{$mobile} {

        margin-top:0;
        text-align: center;

        .a-logo {
            margin-top: 0 0 30px 60px;
            width: 111px;
            height: 30px;
        }

        .m-footer__entry {
            padding: 0;
            text-align: center;

            .grid-x {
                .cell {
                    text-align: left;


                    &:nth-child(1) {
                        margin-bottom: 14px;
                        border-bottom: 1px solid $maintextcolor;
                    }
                }
            }
        }

        .a-footer__links {
            margin: 0 0 29px 5px;
            text-align: left;

            h4, h3, &:--titles {
                margin: 0 0 16px;
                font-family: $font-family-avenir;
                display: inline-block;
            }

            ul {
                li {
                    & ~ li {
                        margin-top: 18px;
                    }
                }
            }

            &--contacts {
                margin: -4px 0 17px 10px;

                h4 {
                    margin: 0 0 1px;
                    font-size: 0.75rem;
                    font-family: $font-family-gfs-didot;
                }
            }
        }

        .a-footer__info {
            padding: 21px 1px 1px;

            p {
                font-size: 1rem;
                line-height: 1.5;
            }
        }

        .m-footer__copyright {
            margin-left: 0;
            text-align: center;
        }

        .m-footer__bar {
            padding: 20px 0 20px;

            .cell {
                &:nth-child(1) {
                    order: 2;
                }

                &:nth-child(2) {
                    order: 1;
                }
            }
        }

        .m-open-chat {
            display: block;
        }
    }
}
    // Helpers
.a-title-underline {
    position: relative;
    display: block;
    padding: 0 0 22px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 96px;
        height: 4px;
        background-color: $brandLightGreen;
    }

    &--center {
        text-align: center;

        &:after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @media #{$mobile} {
        padding: 0 0 16px;

        &:after {
            width: 65px;
            height: 2px;
            background-color: $brandLightGreen;
        }
    }
}

.negate-p {


    p {
        line-height: 1;
        text-rendering: optimizeLegibility;
        margin-bottom: 0;
        font-size: inherit;
    }
}

.text--primary {
    color: $carleRed;
}

.a-ul {
    @for $i from 1 through 12 {
        &--columns-#{$i} {
            // columns: auto $i;
            column-width: auto;
            column-count: $i;

            li {
                padding: 8px 0;
                display: inline-block;
                min-width: 100%;
            }
        }
    }

    &--style-none {
        list-style: none;
        margin-left: 0;
    }

    &--pdf {
        li {
            margin-bottom: 10px;

            &:before {
                content: '';
                @include iconBg(27px, 34px, '/getmedia/74c94670-d480-4e33-ab4c-3d83c9bb37ef/PDF-icon.png', inline-block);
                margin-right: 15px;
                vertical-align: middle;
            }
        }
    }
}

.a-square {
    position: relative;
    width: 100%;

    &__inner {
        position: absolute;
        width: 100%;
        height: 100%;

        &--background {
            background: transparent no-repeat center / cover scroll;
        }
    }

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
}

.hideOnSmallDesktop {
    display: inline;
    color: inherit !important;

    @media (max-width: $responsive-lg) and (min-width: $responsive-sm)  {
        display: none;
    }
}

.a-anchor-id {
    display: block;
    visibility: hidden;
    position: relative;
    top: -115px;

    @media all and (max-width: 1024px) {
        top: -95px;
    }
}

.show-hide-item {
    display: none;
}

#skiptocontent a {
    padding: 6px;
    position: absolute;
    top: -40px;
    left: 0px;
    color: white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 8px;
    background: #BF1722;
    -webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 100;
}

#skiptocontent a:focus {
    position: absolute;
    left: 0px;
    top: 0px;
    outline-color: transparent;
    -webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
}

.o-intro-text {
    .hero-video {
        @include breakpoint(large down) {
            display: none;
        }
    }
}


body {
    .buorg {
        position: fixed;
        z-index: 111111;
        width: 100%;
        top: 0px;
        height: 100%;
        left: 0px;
        border-bottom: 1px solid #000;
        text-align: center;
        color: #000;
        background-color: rgba(0,0,0,0.9);

        .buorg-pad {
            padding: 9px;
            top: 44%;
            position: relative;
            color: white !important;

            .buorg-buttons {
                display: block;
                text-align: center;
                margin-top: 20px;

                #buorgul {
                    @include carleButton;
                    border-color: $carleRed;
                    background-color: $carleRed;
                    color: white;
                    font-weight: 900;
                    border-radius: 0;
                    text-transform: uppercase;
                }

                #buorgig {
                    @include carleButton;
                    border-color: $carleRed;
                    background-color: transparent;
                    color: white;
                    border-radius: 0;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.emergency-alerts {
    background-color: $carleRed;

    span.right-caret {
        display: inline-block;
        position: absolute;
        width: 20px;
        right: 15px;
    }

    .emergency-alert {

        &:last-child {
            .alert-title {
                border-bottom: 0;
            }
        }

        .alert-title {
            padding: 13px 48px 7px;
            margin: 0;
            color: white;
            font-weight: bold;
            font-family: $font-family-avenir;
            font-size: 1rem;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 1px solid white;

            @media all and (max-width: 767px) {
                padding: 0 20px 20px;
                text-align: left;
            }

            i {
                font-size: 1.4rem;
                position: relative;
                top: 4px;
                display: inline-block;
                margin-right: 12px;

                @media all and (max-width:767px) {
                    display: none;
                }
            }

            a {
                @include carleButton;
                background-color: $brand;
                color: white;
                font-size: .7rem;
                margin-left: 15px;
                font-weight: normal;
                padding: 3px 24px;
                position: relative;
                top: -1px;
                //margin: 0 0 20px;

                @media all and (max-width: 767px) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}

.home .mfp-content {
    max-width: 750px;
    padding-top: 60px;
}

div#alert-popup {
    max-width: 750px;
    margin: 0 auto;
    background-color: white;

    .alert-popup-header {
        background-color: $carleRed;
        color: white;
        font-size: 2.5rem;
        padding: 0px 16px 26px;

        @include breakpoint(medium down) {
            font-size: 1.5rem;
            padding: 10px 16px 19px;
        }

        p {
            margin: 0;
            font-weight: bold;

            i {
                font-size: 5rem;
                position: relative;
                top: 12px;

                @include breakpoint(medium down) {
                    font-size: 2.5rem;
                    position: relative;
                    top: 8px;
                }
            }
        }
    }


    .mfp-close {
        top: 0;
        background-color: $linkColor;
        color: white;
        opacity: 1;
        font-size: 1rem;
        width: auto;
        padding: 0 20px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .alert-popup_inner {
        padding: 36px;

        .alert-content:last-child {
            padding-bottom: 0;
        }

        .alert-content:first-child {
            border: 0;
            padding-top: 0;
        }

        .alert-content {
            border-top: 1px solid #555559;
            padding: 48px 0;
        }

        h2 {
            margin-bottom: 36px;

            @include breakpoint(medium down) {
                margin-bottom: 16px;
            }
        }

        p.buttons {
            text-align: center;
        }
    }
}

.accordion {

    [role="tab"]:hover::after, [role="tab"]:focus::after, [role="tab"][aria-selected="true"]::after {
        opacity: 0;
    }

    .accordion-item {
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
        margin-bottom: 8px;

        &.is-active {
            .accordion-title {
                color: white;
                text-decoration: none;

                &:focus {
                    background: $linkColor;
                }

                &:before {
                    content: '\2013';
                    color: #fff;
                }
            }
        }

        .accordion-title {
            padding: 2rem 1.25rem;
            font-size: 1rem;

            &:active {
                background: #fff;
            }

            &:focus {
                background: #fff;
            }

            &:before {
                content: '+';
                top: 50%;
                right: 1rem;
                left: initial;
                margin-top: -0.5rem;
            }

            &:hover {
                text-decoration: none;
                background: #fff;

                &:before {
                    top: 50%;
                    right: 1rem;
                    left: initial;
                    margin-top: -0.5rem;
                }
            }
        }
    }
}

.wait-times-container {
    margin-top: -48px;
    margin-bottom: 100px;

    @include breakpoint(medium down) {
        margin-top: 0;
    }

    .first {
        padding-right: 48px;
        border-right: 1px solid #555559;

        @include breakpoint(medium down) {
            padding: 0;
            border: 0;
        }
    }

    .second {
        padding-left: 48px;

        @include breakpoint(medium down) {
            padding: 0;
        }
    }
}

.thank-you-carle-doctors form select {
    display: none;
}

.thank-you-carle-doctors label {
    display: inline-block !important; 
}

.thank-you-carle-doctors input[type=checkbox] {
    margin-bottom: 38px;
}

.thank-you-carle-doctors label.control-label {
    margin-bottom: 12px;
    margin-top: 0px;
    font-weight: bold;
}

.thank-you-carle-doctors input[type=submit] {
    margin-top: 32px;
    @include carleButton;
    border-color: $carleRed;
    background-color: $carleRed;
    color: white;
    font-weight: 900;
}

input[placeholder="Date"] {
    display: none;
}

select.BBFormSelectList {
    background-position: right -14px center;
    padding-right: 20px !important;
}

.selected.BBFormSelectList::-ms-expand {
    display: initial !important;
}


.covid-19-screening-recommendations {
    li {
        padding-bottom: 0rem;
    }
}

button {
    &.link {
        font-weight: 900;
        text-decoration: none;
        font-family: "Avenir LT W01_65 Medium1475532", sans-serif;    
        
        &:hover {
            text-decoration: underline;
            color: #002A3A;            
        }
    }
    &.lc {
        color: #037D96;
    }
}


tbody  {
    tr
    {
        background-color: #fff;
    }
    tr:nth-child(2n)
    {
        background-color: #FAFAFA;
    }
}