﻿@import "_variables.scss";

.color-bar {
    height: 8px;
    width: 100%;
    padding: 0;
    margin: 0;

    &.purple {
        background-color: $brandPurple;
    }

    &.red {
        background-color: $brandRed;
    }

    &.blue {
        background-color: #62B1BF;
    }

    &.green {
        background-color: #AACD58;
    }
}

.social {
    a {
        color: #037D96;
        font-size: 2.0rem;
        margin-right: 15px;
    }
}

.carle-logo img {
    max-width: 131px;
    width: 100%;
}


.caos-logo img {
    max-width: 300px;
    width: 100%;
}

@media all and (max-width: 1023px) {
    .disclaimer {
        margin-top: 16px;
    }
}
