﻿// background colors:

$carleRed: #C32032;
$carleLightGrey: #f9f9f9;
$carleMediumGrey: #d8d8d8;
$carleDarkBlue: #002A3A;
$white: #fff;
$brand: #002A3A;
$grey: #FAFAFA;
$grey-100: #8b8b8e;
$brandPurple: #A65E97;
$brandGreen: #497E1F;
$brandRed: #AF2534;
$brandBlue: #037D96;
$brandLightBlue: #62B1BF;
$brandLightGreen: #AACD58;


// text colors:
$maintextcolor: #555559;
$linkColor: #037D96;
$linkHoverColor: #002A3A;
$carleButtonHoverColor: #900000;
$topNavLinkHoverColor: #E56171;
$focusColor: #FFB900;


// horizontal rules
$darkRuleLine: #CCCCCE;

//font sizes

$h1Size: 3rem;
$h2Size: 3rem;
$h3Size: 1.5rem;
$h4Size: 1.25rem;
$h5Size: 1rem;
$h6Size: 0.875rem;


// Font Family

$font-family-avenir: 'Avenir LT W01_65 Medium1475532', sans-serif;
$font-family-gfs-didot: linotype-didot-roman, serif;

$responsive-xxs: 479px;
$responsive-xs: 767px;
$responsive-sm: 1023px;
$responsive-md: 1200px;
$responsive-lg: 1280px;

$mobile-small: '(max-width: #{$responsive-xxs}) ';
$mobile-medium: '(max-width: #{$responsive-xs}) ';
$mobile: '(max-width: #{$responsive-sm}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$large-desktop: '(max-width: #{$responsive-lg}) ';