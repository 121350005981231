// Logo

.a-logo {
    display: inline-block;
    flex: 0 0 309px;
    width: 309px;
    height: 54px;
    margin-left: -10px;
    background-size: cover;
    background-position: center center;

    @media #{$mobile} {
        flex: 0 0 185px;
        width: 185px;
        height: 34px;
        margin-left: 60px;
    }
}

.logo-small {
    display: inline-block;
    width: 53px;
    height: 51px;
    margin-left: 0px;
    background-position: center center;
}
