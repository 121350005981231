@import '../global/_variables.scss';

// Cards

.cards {
    .m-card-media:last-child {
        margin-bottom: 0;
    }
}

.m-cards {
    margin: 0 0 14px;

    .grid-x {
        margin: 0 -16px;

        .cell {
            padding: 0 16px;
            display: flex;
        }
    }

    &--small {
        padding: 0 120px;

        .m-card-article__actions {
            padding: 48px 0 0;
            text-align: center;
        }
    }

    @media #{$small-desktop} {
        &--small {
            padding: 0 40px;
        }
    }

    @media #{$mobile} {
        .grid-x {
            margin: 0;

            .cell {
                padding: 0;
            }
        }

        &--small {
            padding: 0;

            .m-card-article__actions {
                padding: 20px 0 0;
                text-align: center;
            }
        }
    }
}

// Card

.m-card {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 30px;
    padding: 32px 32px 24px;
    background-color: $white;
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.1);

    h3, h2 {
        margin: 0 0 12px;
        font-family: "Avenir LT W01_65 Medium1475532", sans-serif;
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1.29;

        span {
            font-size: 1rem;
        }
    }

    .a-card__header {
        margin-bottom: 60px;
    }

    .a-card__actions {
        position: absolute;
        bottom: 18px;
        right: 24px;
        margin-top: 48px;

        a {
            color: $linkColor;
            font-size: 1rem;
            font-weight: 900;
        }

        &--button {
            right: inherit;
            //position: initial;
            a {
                color: white;

                &.a-btn-inverse {
                    color: $carleRed;
                }

                &:hover {
                    text-decoration: none;
                    //                    color: $carleRed;
                    //                    background-color: transparent;
                }
            }
        }

        &--small {
            margin-top: 0;
        }
    }

    &--alt {
        padding: 24px 40px 30px 16px;

        p {
            margin: 0;
        }
    }

    .a-btn-main {
        padding: 11px 54px;
        text-transform: uppercase;
        font-size: 0.875rem !important;


        &--small {
            padding: 11px 11px;
        }
    }

    @media #{$mobile} {
        padding: 20px 20px;

        .a-card__actions {
            position: relative;
            bottom: auto;
            right: auto;
            text-align: right;

            a {
                color: $linkColor;
                font-size: 1rem;
                font-weight: 900;
            }
            //a {
            //    color: white;
            //}
        }

        .a-card__header {
            margin-bottom: 0px;
        }
    }
}

// Card Media

.m-card-media {
    margin: 0 0 30px;

    ul {
        margin: 0 0 20px;
        columns: 2;
        font-size: 1rem;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 15px;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 9px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $maintextcolor;
            }

            & ~ li {
                margin-top: 15px;
            }
        }
    }

    .grid-x {
        flex-direction: row-reverse;
    }

    .cell {
        display: flex;
    }

    .m-card-media__image {
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 55px;
        }
    }

    .m-card-media__content {
        width: 100%;
        padding: 24px 20px 36px 30px;
        background-color: $white;
        box-shadow: 0 8px 17px 0 rgba(0,0,0,0.1);
    }

    .m-card-media__actions {
        display: flex;
        justify-content: space-between;
        padding: 40px 0 0;
    }

    .a-btn-inverse {
        i {
            position: relative;
            display: inline-block;
            right: -7px;
        }

        padding: 11px 45px;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
    }

    .a-btn-main {
        padding: 11px 54px;
        text-transform: uppercase;
        font-size: 0.875rem;

        &--small {
            padding: 11px 11px;
        }
    }


    &--awards {
        h3 {
            margin: 0 0 25px;
            font-size: 2rem;
        }

        .m-card-media__content {
            padding: 33px 20px 24px 16px;
        }

        .m-card-media__actions {
            padding: 40px 0 0;
            display: block;

            a {
                padding: 11px 54px;
                display: inline-block;
                margin-right: 16px;
            }

            .a-btn-main {
                padding: 11px 25px;
                font-size: 14px;
                text-align: center;
            }

            .a-btn-inverse {
                padding: 11px 20px;
                font-size: 14px;
                text-align: center;
                text-transform: uppercase;
                margin-right: 0;
            }
        }
    }

    &--location {
        .m-card-media__image {
            width: 460px;

            @media #{$mobile} {
                width: 100%;
            }
        }

        .m-card-media__content {
            h2 {
                font-size: 2rem;
                margin-top: 32px;
                margin-bottom: 60px;
            }
        }
    }

    &--offset-bottom {
        margin: 0 0 150px;
    }

    &--support {
        h3 {
            margin: 0 0 19px;
            font-size: 1.9375rem;
        }

        .m-card-media__content {
            padding: 31px 20px 36px 30px;
        }

        .a-btn-main {
            padding: 12px 29px;
        }

        .m-card-media__actions {
            padding: 30px 0 0;
        }
    }

    &--patient {
        margin: 0 0 59px;
    }


    @media #{$mobile} {
        h2 {
            font-size: 2rem;
        }

        li {
            margin: 0 0 20px;
            font-size: 0.875rem;
            list-style-type: none;
        }

        .grid-x {
            display: block;
        }

        .m-card-media__image {
            padding: 0 0 60%;
        }

        .m-card-media__content {
            padding: 20px 15px;
        }

        .m-card-media__actions {
            padding: 10px 0 0;
            //.a-btn-inverse {
            //    width: 100%;
            //    text-align: center;
            //}
            a {
                display: block;
                width: 100%;
                margin: 0 0 10px;
                text-align: center;
            }
        }

        &--awards {

            .m-card-media__actions {
                display: block;
                justify-content: space-between;


                a {
                    display: block;
                    width: 100%;
                    padding: 11px 54px;
                    text-align: center;
                }

                .a-btn-main {
                    display: block;
                    width: 100%;
                    padding: 11px 20px;
                    margin: 0 0 10px;
                    text-align: center;
                    font-size: 14px;
                    text-align: center;
                }

                .a-btn-inverse {
                    display: block;
                    text-align: center;
                    padding: 11px 20px;
                    font-size: 14px;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
    }
}
// Card Article
.m-card-article {
    img {
        width: 100%;
    }

    h5 {
        margin: 0 0 32px;
        color: $linkColor;
        font-weight: 700;
        font-family: $font-family-avenir;
        line-height: 1.13;

        a {
            color: $linkColor;

            &:hover {
                text-decoration: none;
                color: $carleRed;
            }
        }
    }

    p {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.25;
    }

    .m-card-article__image {
        padding: 0 0 60%;
        background-size: cover;
        background-position: center center;
    }

    .m-card-article__content {
        position: relative;
    }

    &--small {
        flex: 0 0 100%;
        max-width: 100%;
        box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);

        h5 {
            min-height: 36px;
        }

        .m-card-article__content {
            padding: 15px 25px 20px 54px;
        }

        .m-card-article__image {
            position: relative;
            padding: 0 0 50%;
            background-size: cover;
            background-position: center center;

            a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: background-color .3s ease;

                &:hover {
                    background: rgba(255,255,255, .3);
                }
            }
        }

        .a-card-article__date {
            position: absolute;
            top: 13px;
            left: 16px;
            width: 32px;
            height: 32px;
            box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);
            text-align: center;

            span {
                display: inline-block;
                margin: 0 0 4px;
                font-size: 10px;
                color: $carleRed;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 1;
            }

            p {
                font-size: 10px;
                font-weight: 700;
                line-height: 1;
            }
        }
    }

    @media #{$mobile} {
        margin: 0 0 30px;

        h5 {
            min-height: unset;
            margin: 0 0 20px;
        }

        &--small {
            .m-card-article__content {
                padding: 20px 15px 15px 60px;
            }
        }
    }
}


.m-cards-services {
    //padding: 0 100px;
    .grid-x {
        margin: 0 -24px;
    }

    .cell {
        display: flex;
        padding: 0 24px;
    }

    @media #{$small-desktop} {
        padding: 0 20px;
    }

    @media #{$mobile} {
        padding: 0;

        .grid-x {
            margin: 0;
        }

        .cell {
            display: flex;
            padding: 0;
        }
    }
}

.m-card-service {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 0.875rem;
    line-height: 1.29;
    background-color: $white;
    box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);

    p {
        margin: 0 0 5px;
        line-height: 1.29;
    }

    h4, h2, h3 {
        margin: 0 0 20px;
        letter-spacing: .025em;
        font-size: 1.5rem;
        line-height: 1.17;

        a {
            font-family: $font-family-avenir;
            font-weight: 700;
        }
    }

    .a-btn-inverse {
        padding: 13px 40px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .m-card-service__image {
        padding: 0 0 57%;
        background-size: cover;
        background-position: center center;
    }

    .m-card-service__content {
        padding: 11px 20px 45px 28px;
    }

    .m-card-service__actions {
        position: absolute;
        bottom: 24px;
        left: 27px;
        display: none; //for ADA compliance
    }

    @media #{$mobile} {
        margin: 0 0 20px;

        .m-card-service__content {
            padding: 20px 15px;
        }

        .m-card-service__actions {
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            padding: 25px 0 0;
        }

        .a-btn-inverse {
            width: 100%;
            text-align: center;
        }
    }
}

// Card Main
.a-card-main {
    margin: 0 0 9px;
    padding: 0 60px 0 0;
    color: $maintextcolor;

    h1,h2 {
        margin: 0 0 44px;
    }

    h5 {
        margin: 0 0 2px;
        font-family: $font-family-avenir;
        font-size: 1rem;
    }

    p {
        margin: 0 0 20px;
        font-size: 1rem;
        line-height: 1.5;

        &:last-child {
            margin-bottom: 40px;
        }
    }

    .a-btn-main {
        padding: 12px 41px;
        margin: 0 29px 0 0;

        &--squeezed {
            padding-left: 25px;
            padding-right: 25px;

            @media#{$small-desktop} {
                padding-left: 10px;
                padding-right: 10px;
                margin-bottom:10px;
            }
        }
    }

    .a-btn-inverse {
        padding: 12px 51px;
        text-transform: uppercase;

        &--squeezed {
            padding-left: 25px;
            padding-right: 25px;

            @media#{$small-desktop} {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    .a-card-main__actions {
        padding: 30px 0 25px;
    }

    .grid-x--revers {
        flex-direction: row-reverse;
    }

    &--image {
        margin: 0 64px;
        padding: 0 0 53px;
        border-bottom: 1px solid $maintextcolor;

        h2 {
            margin: 0 0 31px;
        }

        .a-card-main__content {
            padding: 0 10px 0 33px;
        }

        .a-card-main__image {
            padding: 17px 0px 0 73px;
            text-align: right;
        }
    }

    &--support {
        //margin: 0;
        padding: 9px 83px;

        h2 {
            margin: 0 0 37px;
            padding: 0 0 37px;
            line-height: 1.12;
            font-size: 3rem;
        }

        h5 {
            margin: 0 0 21px;
            font-weight: 600;
        }

        .grid-x {
            align-items: flex-end;
        }

        .a-card-main__content {
            padding: 0 40px 0 0;
        }

        .a-card-main__actions {
            padding: 0 0 84px;
            text-align: right;

            .a-btn-main {
                margin: 0;
                padding: 12px 10px;
                text-align: center;
                min-width: 208px;
                text-transform: uppercase;
                font-weight: 400;
            }
        }
    }

    &--large {
        margin-bottom: 0;
        padding-bottom: 55px;
        border-bottom: 1px solid rgba(85,85,85, .5);

        h2 {
            margin: 0 165px 52px 0;
            padding: 0 0 52px;
            line-height: 1.12;
        }

        .a-card-main__group {
            h5 {
                margin: 0 0 25px;

                span {
                    display: inline-block;
                    padding: 6px 5px;
                    min-width: 200px;
                    background-color: $brand;
                    color: $grey;
                    font-size: 1rem;
                }
            }

            & ~ .a-card-main__group {
                margin-top: 51px;
            }
        }

        .a-card-main__actions {
            padding: 6px 0 0;
        }
    }


    @media #{$mobile} {
        margin: 0 0 20px;

        h1, h2 {
            margin: 24px 0 24px;
            font-size: 2rem;
        }

        p {
            margin: 0 0 10px;
            font-size: 1rem;
        }

        .a-card-main__actions {
            padding: 20px 0 0;
            display: block;

            a {
                display: block;
                width: 100%;
                margin: 0 0 10px;
                text-align: center;
            }
        }

        &--image {
            margin: 0;
            padding: 0 0 20px;
            border-bottom: 1px solid $maintextcolor;

            h2 {
                margin: 0 0 10px;
            }

            .a-card-main__content {
                padding: 0 0;
            }

            .a-card-main__image {
                padding: 17px 0px;
                text-align: right;
            }
        }

        &--support {
            margin: 0;
            padding: 8px 5px 20px;

            h2 {
                margin: 0 0 31px;
                padding: 0 0 19px;
            }

            h5 {
                margin: 0 0 21px;
                font-weight: 600;
            }

            p {
                margin: 0 0 18px;
            }

            .grid-x {
                align-items: flex-end;
            }

            .a-card-main__content {
                padding: 0 17px 0 0;
            }

            .a-card-main__actions {
                padding: 16px 0 0;
                text-align: right;

                .a-btn-main {
                    margin: 0;
                    padding: 12px 10px;
                    text-align: center;
                    min-width: 208px;
                    text-transform: uppercase;
                    font-weight: 400;
                }
            }
        }

        &--large {
            padding-bottom: 55px;
            border-bottom: 1px solid rgba(85,85,85, .5);

            h2 {
                margin: 0 0px 30px 0;
                padding: 0 0 20px;
                line-height: 1.12;
            }

            .a-card-main__actions {
                padding: 6px 0 0;

                .a-btn-main {
                    width: auto;
                    display: inline-block;
                }
            }
        }
    }
}

.a-feature-images img {
    width: 100%;
}

// Cards Media
.a-cards-media {
    .a-cards-media__actions {
        padding: 50px 0;

        .a-btn-main {
            padding: 11px 23px;
        }
    }
}
// Card Media Inverse
.a-card-media-inverse {
    display: flex;
    box-shadow: 0 4px 17px 0 rgba(85,85,89,0.1);

    h3 {
        margin: 0 0 17px;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: $font-family-avenir;
    }

    p {
        margin: 0 0 10px;
    }

    a {
        font-weight: 700;
    }

    .a-card-media-inverse__actions {
        padding: 34px 13px 0;
    }

    .a-card-media-inverse__image {
        width: 280px;
        padding: 0 0 24%;
        background-size: cover;
        background-position: center center;
    }

    .a-card-media-inverse__content {
        flex: 1;
        padding: 28px 32px 20px;
    }

    .a-card-media-inverse__actions {
        text-align: right;
    }

    @media #{$mobile} {
        display: block;

        .a-card-media-inverse__image {
            display: block;
            width: 100%;
            padding: 0 0 60%;
        }
    }
}

// Card No Media Inline Inverse
.a-card-inline-inverse {
    display: flex;
    box-shadow: 0 4px 17px 0 rgba(85,85,89,0.1);

    h3 {
        margin: 0 0 17px;
        font-size: 1.5rem;
        font-family: $font-family-gfs-didot;
    }

    p {
        margin: 0 0 10px;
    }

    a {
        font-weight: 700;
    }

    .a-card-inline-inverse__actions {
        padding: 34px 13px 0;
    }


    .a-card-inline-inverse__content {
        flex: 1;
        padding: 28px 32px 20px;
    }

    .a-card-inline-inverse__actions {
        text-align: right;
    }

    @media #{$mobile} {
        display: block;
    }
}


// Cards Team
.a-cards-team {
    .grid-x {
        margin: 0 -16px;
    }

    .cell {
        display: flex;
        padding: 0 16px;
    }

    @media #{$mobile} {
        .grid-x {
            margin: 0;
        }

        .cell {
            display: block;
            padding: 0;
        }
    }
}
// Card Team
.a-card-team {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 32px;
    box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);
    background-color: $white;
    font-size: .875rem;

    p {
        line-height: 1.43;
        font-weight: bold;
    }

    h3 {
        margin: 0 0 0px;
        color: $carleRed;
        font-family: $font-family-avenir;
        font-weight: 700;
        font-size: 1rem;
        min-height: 24px;

        span {
            font-size: 1rem;
            color: inherit;
            font-family: inherit;
            font-weight: inherit;
        }
    }

    .a-card-team__image {
        flex: 0 0 87px;
        max-width: 87px;
        padding: 24px 13px 0 16px;
    }

    .a-card-team__content {
        padding: 22px 20px 22px 1px;
        flex: 1;

        &--residency {
            p:first-of-type {
            font-size: 0.75rem;
            color: #C32032;
            margin-bottom: 0;
            font-weight: 700;
            text-transform: uppercase;
            }
        }
    }

    &--no-image {
        .a-card-team__content {
            padding: 22px 20px 22px 17px;
            flex: 1;
        }
    }


    @media #{$mobile} {
        margin: 0 0 15px;
    }
}
// Card Team Alt
.a-card-team-alt {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 32px;
    padding: 23px 16px 20px;
    box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);
    background-color: $white;
    font-weight: 400;
    font-size: .875rem;
    transition: box-shadow .3s ease;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    h5 {
        margin: 0 0 20px;
        color: $maintextcolor;
        font-family: $font-family-avenir;
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: .03em;
    }

    .a-card-team__content--residency {
            p:first-of-type {
                font-size: 0.75rem;
                color: #C32032;
                margin-bottom: 0;
                font-weight: 700;
                text-transform: uppercase;
            }
    }
   

    @media #{$mobile} {
        margin: 0 0 15px;
    }
}

// TODO: Reconcile these styles with above
.o-leadership-cards {
    .m-leadership-card {
        box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);
        background-color: $white;

        &__image {
            width: 87px;

            img {
                width: 100%;
            }
        }

        &__content {
            font-weight: 400;
            font-size: .875rem;

            h5 {
                font-family: $font-family-avenir;
                font-weight: 700;
                font-size: 1rem;
            }
        }
    }
}