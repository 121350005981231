// Section



.section {
    .grid-container {
        max-width: 78rem;
    }

    .m-section-entry {
        padding: 49px 72px 0;
    }

    .section__head {
        margin: 0 0 20px;
        text-align: center;

        .section__head-entry {
            padding: 0 50px;
        }
    }

    &--offset-bottom {
        padding: 0 0 73px;
    }

    &--offset-top {
        padding: 56px 0 48px;
    }

    &--alt {
        padding: 80px 0;

        h2 {
            line-height: 1.13;
        }
    }

    @media #{$mobile} {
        h2 {
            font-size: 2rem;
        }

        .m-section-entry {
            padding: 20px 0px 0;
        }

        .section__head {
            margin: 0 0 10px;

            .section__head-entry {
                padding: 0;
            }
        }

        &--alt {
            padding: 0 0 30px;

            h2 {
                line-height: 1.13;
            }
        }
    }
}

// Section Alt

.section-alt {
	padding: 68px 0 0;

	.grid-container {
		max-width: 78rem;
	}


	h2 {
		line-height: 1.13;
	}

	.m-section-inner {
		padding: 0 0 0 0px;
	}

	@media #{$mobile} {
		padding: 20px 0 20px;

		h2 {
			font-size: 2rem;
		}

		.m-section-inner {
			padding: 0;
		}
	}
}

// Section Cards

.o-section-cards {
    padding: 60px 0;

    .grid-container {
        max-width: 78rem;

        &.fluid {
            max-width: unset;
        }
    }


    &--offset-bottom {
        padding: 60px 0 30px;
    }

    &--alt {
        //padding: 0px 0 74px;
        background-color: $grey;
    }

    @media #{$mobile} {
        padding: 0;

        &--alt {
            padding: 0 0 10px;
            background-color: $grey;
        }
    }

    @at-root .for-providers & + & {
        padding-top: 0;
    }
}

// Section Tabs

.o-section-tabs {
	.m-section-tabs__head {
		margin: 0 0 33px;

		h2 {
			position: relative;
			margin: 0 0 10px;
			text-align: center;
			font-size: 3rem;

			a {
				position: absolute;
				top: 8px;
				right: -85px;
				height: 60px;
				width: 60px;
				border-radius: 20px;
				background-color: $linkColor;
				color: $white;
				font-size: 30px;
				line-height: 56px;
			}
		}
	}

	@media(max-width: 1400px){
		.m-section-tabs__head {
			h2 {
				a {
					right: 10px;
				}
			}
		}

	}

	@media #{$mobile} {
		.m-section-tabs__head {
			h2 {
				font-size: 2rem;
				padding-right: 40px;

				a {
					right: 0;
					top: 8px;
					height: 30px;
					width: 30px;
					font-size: 20px;
					border-radius: 10px;
					line-height: 30px;
				}
			}
		}
	}
}


// Section Callout

.m-section-callout {
    padding: 48px 0;
    //margin: 0 0 100px;
    background-color: $brand;
    color: $white;
    text-align: center;

    h3, h2 {
        margin: 0 0 26px;
        font-size: 2rem;
        font-family: $font-family-avenir;
    }

    .a-section-callout-title {
        margin: 0 0 26px;
        font-size: 2rem;
        font-family: $font-family-avenir;
        display: block;
    }

    p {
        margin: 0 0 10px;
    }

    .a-btn-main {
        padding: 11px 22px;
    }

    .a-section-callout__logo {
        display: inline-block;
        width: 133px;
        height: 40px;
        margin: 0 0 14px;
        background-size: cover;
        background-position: center center;
    }

    .a-section-callout__actions {
        padding: 19px 0 0;
    }

    @media #{$mobile} {
        //margin: 0 0 50px;
        h3, h2, .a-section-callout-title {
            font-size: 1.5rem;
        }
    }
}

// Section History

.o-section-history {
    padding: 6px 0 30px;

    h3 {
        margin: 0 0 17px;
        font-size: 2rem;
    }

    p {
        letter-spacing: -.01em;
    }

    .a-cards-media {
        margin: 0 0 47px;
    }

    .a-section-history__group {
        padding: 0 5px;
        margin: 0 0 48px;
    }

    

    .o-intro {
        margin-bottom:15px;
        @media #{$mobile} {
            margin-bottom:0px;
        }
    }
}

// Section Team

.o-section-team {
    padding: 30px 0;

    h2 {
        margin: 0;
    }

    .a-section-team__head {
        text-align: center;

        h2 {
            font-size: 2.375rem;
        }
    }

    .a-section-team__body {
        padding: 0 55px;

        &--boxed {
            box-shadow: 0 8px 17px 0 rgba(85,85,89,.1);
            padding-top:40px;
            padding-bottom:40px;
        }
    }

    &--grey {
        background: rgba(250,250,250, .6);
    }



    @media #{$small-desktop} {
        .a-section-team__body {
            padding: 0 20px;
        }
    }

    @media #{$mobile} {
        padding: 30px 0;

        h2 {
            font-size: 2rem;
        }

        .a-section-team__head {
            margin: 0 0 20px;
        }
    }
}

// Section Tiles

.o-section-tiles {
    padding: 30px 0 64px;
    background-color: $grey;

    .m-section-tiles__header {
        margin: 0 0 33px;
        text-align: center;

        h3 {
            margin: 0 0 10px;
            font-size: 2.5rem;
        }
    }

    @media #{$mobile} {
        padding: 0;

        .m-section-tiles__header {
            margin: 0 0 26px;

            h3 {
                font-size: 1.5rem;
            }
        }
    }
}

//Section Contact

.o-section-contact {
    padding: 0 0 0;
    background-color: $grey;

    h5, h2.a-small-title {
        display: inline-block;
        color: $maintextcolor;
        font-weight: 900;
        text-transform: uppercase;
        font-family: $font-family-avenir;
        font-size:1rem;
    }

    .a-title-underline {
        padding: 0 0 27px;

        &:after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .m-section-contact__header {
        margin: 0 0 40px;
        text-align: center;
    }

    .m-section-contact__body {
        padding: 0 82px;

        h5 {
            display: block;
        }

        a {
            color: $maintextcolor;
            font-weight: 300;
        }
    }

    @media #{$mobile} {
        padding: 0px 0 16px;

        .a-title-underline {
            padding: 0 0 26px;

            &:after {
                left: 50%;
                height: 4px;
                transform: translateX(-50%);
            }
        }


        .m-section-contact__header {
            margin: 0 0 23px;
        }

        .m-section-contact__body {
            padding: 0 6px;

            h5 {
                display: block;
            }

            a {
                color: $maintextcolor;
                font-weight: 300;
            }
        }
    }
}

// Section Links

.o-section-links {
    padding: 13px 0 64;

    h5 {
        margin: 0 0 23px;
        font-family: $font-family-avenir;

        span {
            display: inline-block;
            padding: 5px 7px;
            min-width: 200px;
            background-color: $brand;
            color: $grey;
            font-size: 1rem;
        }
    }

    h3, h2.a-small-title {

        font-size:2.1rem;
        margin-top:30px;
        margin-bottom:20px;
         @media #{$mobile} {
             margin-top:0;
         }
    }

    .list-links {
        li {
            & ~ li {
                margin: 19px 0 0;
            }
        }
    }
}

//Section Text

.o-section-text {
    padding: 0px 0 31px;

    h2 {
        margin: 0 0 36px;
        letter-spacing: .05em;
        line-height: 1.1;
    }

    .a-title-underline {
        padding: 0 0 35px;
    }

    p {
        margin: 0 0 20px;
        line-height: 1.5;
        letter-spacing: -.01em;
    }

    .list-links {
        margin: 0 0 33px;

        li {
            & ~ li {
                margin-top: 4px;
            }
        }
    }

    .list-bullets {
        margin: 0 0 0 28px;

        li {
            padding: 0 0 0 7px;

            & ~ li {
                margin-top: 3px;
            }
        }
    }

    .m-cards {
        padding: 0 82px;
    }

    .m-card {
        .a-card__actions {
            right: auto;
            left: 24px;

            a {
                color: $white;

                &:hover {
                    color: $carleRed;
                }
            }
        }
    }

    .a-section-text__group {
        & ~ .a-section-text__group {
            margin: 55px 0 0;
            padding: 50px 0 0;
            border-top: 1px solid rgba(85,85,89, .5);
        }
    }

    .o-section-text__actions {
        padding: 20px 0 0;

        .a-btn-main {
            padding: 12px 20px;
        }
    }

    .a-section-text__body {
        padding: 24px 0 0;
        margin: 0 -24px;

        h5 {
            margin: 0 0 17px;
            text-align: center;
            font-weight: 900;
            font-family: $font-family-avenir;
        }
    }

    .a-section-text__foot {
        padding: 5px 74px;

        .a-section-text__actions {
            display: none;
        }
    }

    .map {
        float: right;
        margin-left: 50px;
    }

    &--alt {
        padding: 99px 0 31px;

        p {
            margin: 0 0 19px;
        }

        .list-bullets {
            margin: 0 0 21px 29px;

            span {
                display: block;
                text-transform: uppercase;
            }

            li {
                padding: 0 0 0 6px;
            }
        }

        .a-section-text__head {
            padding: 0 81px;

            p {
                margin: 0 0 24px;
            }
        }

        .a-section-text__body {
            padding: 70px 0px 0 82px;
            margin: 0;
        }
    }

    &--patient {
        padding: 0;
    }

    @media #{$large-desktop} {
        .a-section-text__body {
            margin: 0;
        }
    }

    @media #{$mobile} {
        padding: 0;

        h2 {
            margin: 0 0 32px;
            font-size: 2rem;
        }

        .map {
            float: none;
            margin: 0 0 30px;
        }

        .m-cards {
            padding: 0 0;
        }

        .m-card {
            .a-card__actions {
                right: auto;
                left: 0;
                text-align: left;
            }
        }

        .a-title-underline {
            padding: 0 0 28px;
        }

        .list-links {
            margin: 0 0 29px;

            li {
                & ~ li {
                    margin-top: 4px;
                }
            }
        }

        .list-bullets {
            margin: -10px 0 0 30px;
        }

        .a-section-text__head {
            padding: 0 5px;
        }

        .a-section-text__group {
            & ~ .a-section-text__group {
                margin: 27px 0 0;
                padding: 33px 0 0;
            }
        }

        .a-section-text__body {
            padding: 3px 0 0;

            h5 {
                margin: 0 0 9px 7px;
                text-align: left;
            }
        }

        .a-section-text__foot {
            padding: 33px 6px 0;

            .a-section-text__actions {
                display: block;

                .a-btn-main {
                    display: block;
                    text-align: center;

                    i {
                        position: relative;
                        display: inline-block;
                        right: -3px;
                        transform: rotate(180deg);
                        transition: transform .3s ease;
                    }

                    &.less {
                        i {
                            transform: rotate(-0deg);
                        }
                    }
                }
            }

            .a-section-text__foot-entry {
                display: none;
                padding: 26px 0 0;
            }
        }

        &--alt {
            .a-section-text__group-actions {
                max-width: 100%;
                padding: 0 40px
            }

            .a-section-text__body {
                padding: 30px 0px 0 10px;
                margin: 0;
            }
        }

        &--small {
            padding-bottom: 0;

            h2 {
                margin: 0;
            }

            .a-title-underline {
                padding: 0 0 15px;
            }
        }
    }
}

// Section Bill

.o-section-bill {
    padding: 0 0;

    h2 {
        margin: 0 0 10px;
        letter-spacing: .02em;
        line-height: 1.15;
    }

    h3 {
        margin: 0 0 46px;
        font-size: 2rem;
        letter-spacing: .04em;
    }

    .a-title-underline {
        padding: 0 0 50px;
    }

    .a-section-bill__head {
        margin: 0 0 41px;
    }

    .a-section-bill__head-inner {
        padding: 0 82px;
        width: 604px;
    }

    .a-section-bill__group {
        display: flex;
        align-items: flex-start;

        & ~ .a-section-bill__group {
            margin-top: 57px;
        }

        .a-content {
            position: relative;
            flex: 0 0 604px;
            max-width: 604px;

            span {
                position: absolute;
                transform: translate(-50%, -50%);
            }

            .a-content__inner {
                position: relative;
            }
        }

        &:first-child() {
            .a-content {
                span {
                    &:nth-child(1) {
                        top: 31.5%;
                        left: 5%;
                    }

                    &:nth-child(2) {
                        top: 69.5%;
                        left: 6.5%;
                    }

                    &:nth-child(3) {
                        top: 83.5%;
                        left: 54.5%;
                    }

                    // &:nth-child(4) {
                    //     top: 38%;
                    //     right: 10%;
                    // }

                    // &:nth-child(5) {
                    //     top: 49.5%;
                    //     left: 4.5%;
                    // }

                    // &:nth-child(6) {
                    //     top: 59.5%;
                    //     left: 32.5%;
                    // }

                    // &:nth-child(7) {
                    //     bottom: 2.5%;
                    //     left: 58%;
                    // }
                }
            }
        }

        &:nth-child(2) {
            .a-content {
                span {
                    &:nth-child(1) {
                        top: 10%;
                        left: 6%;
                    }

                    &:nth-child(2) {
                        top: 68.5%;
                        left: 0%;
                    }

                    // &:nth-child(3) {
                    //     top: 44%;
                    //     left: 8%;
                    // }
                }
            }
        }

        &:nth-child(3) {
            .a-content {
                span {
                    &:nth-child(1) {
                        top: 11.5%;
                        right: 19%;
                    }

                    &:nth-child(2) {
                        top: 14.5%;
                        left: 4%;
                    }

                    &:nth-child(3) {
                        top: 24.5%;
                        left: 19%;
                    }

                    &:nth-child(4) {
                        top: 59%;
                        right: 5%;
                    }
                    // &:nth-child(5) {
                    //     top: 18%;
                    //     right: 0.5%;
                    // }

                    // &:nth-child(6) {
                    //     top: 65.5%;
                    //     right: 0.5%;
                    // }
                }
            }
        }
    }

    .a-section-bill__inner {
        padding: 0 82px;
    }

    .sidebar-bill {
        flex: 1;
        padding: 70px 0 0 52px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding: 9px 0px 0 69px;

                & ~ li {
                    margin-top: 39px;
                }

                p {
                    margin: 0;
                }
            }

            .number-oval {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    @media #{$small-desktop} {
        .a-section-bill__inner {
            padding: 0 0px;
        }
    }

    @media #{$mobile} {
        padding: 28px 0 13px;

        h2 {
            margin: 0 0 10px;
            letter-spacing: .05em;
            line-height: 1.1;
            font-size: 1.5rem;
        }

        h3 {
            margin: 0 0 30px;
            font-family: $font-family-avenir;
            font-size: 1.125rem;
        }

        .a-title-underline {
            padding: 0 0 29px;

            &:after {
                width: 96px;
            }
        }

        .a-section-bill__head {
            margin: 0 0 30px;
        }

        .a-section-bill__head-inner {
            padding: 0 5px;
            width: 100%;
        }

        .a-section-bill__group {
            display: block;
            max-width: 604px;
            margin: 0 auto;

            .a-content {
                max-width: 100%;

                .number-oval {
                    width: 19px;
                    height: 19px;
                    font-size: 9px;
                    line-height: 19px;
                }
            }
        }

        .sidebar-bill {
            padding: 30px 0 0 4px;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    position: relative;
                    padding: 6px 0px 0 56px;

                    & ~ li {
                        margin-top: 30px;
                    }

                    p {
                        font-size: 0.875rem;
                        letter-spacing: .02em;
                    }
                }

                .number-oval {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

// Section Blog

.o-section-blog {
    padding: 0;

    h2 {
        margin: 0 0 37px;
        letter-spacing: .05em;
    }

    .a-title-underline {
        padding: 0 0 27px;
    }

    h3 {
        margin: 0 0 15px;
        letter-spacing: .05em;
    }

    h5 {
        margin: 0 0 4px;
        font-size: 1.5rem;
        letter-spacing: .05em;
    }

    p {
        margin: 0 0 18px;
    }

    ol {
        margin: 0 0 42px 27px;
        padding: 0;

        li {
            padding-left: 7px;

            & ~ li {
                margin-top: 18px;
            }
        }
    }

    .list-bullets {
        margin: 0 0 0 29px;

        li {
            padding: 0 0 0 5px;

            & ~ li {
                margin-top: 4px;
            }
        }
    }

    .a-section-blog__head {
        padding: 0 0 13px;
    }

    .a-section-blog__body {
        padding: 0px 0 0px;
        background-color: $grey;
    }

    .a-section-blog__group {
        position: relative;

        & ~ .a-section-blog__group {
            margin: 48px 0 0;
            padding: 43px 0 0;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 77%;
                height: 1px;
                margin-left: 8.33333%;
                background-color: rgba(85,85,89, .5);
            }
        }
    }

    .a-section-blog__group-small {
        padding: 6px 0 0 0px;

        p {
            margin: 0 0 6px;

            a {
                strong {
                    color: $linkColor;
                }
            }
        }
    }

    .a-section-blog__foot {
        padding: 55px 0 0;

        h6 {
            margin: 0 0 18px;
            font-size: 1rem;
            text-align: center;
            font-weight: 900;
        }
    }

    &--questions {
        padding: 0;
        font-weight: 300;

        & ~ .o-section-blog {
            padding: 21px 0 0;
        }

        .grid-x {
            margin: 0 -40px;
        }

        .cell {
            padding: 0 40px;
        }

        h2 {
            margin: 0 0 39px;
            letter-spacing: .05em;
            line-height: 1.2;
        }

        .a-title-underline {
            padding: 0 0 51px;
        }

        h3 {
            margin: 0 0 37px;
            font-size: 2rem;
        }

        h6 {
            margin: 0;
            font-size: 1rem;
            line-height: 1.6;
            font-weight: 900;
        }

        p {
            margin: 0 0 12px;
            line-height: 1.6;
            font-weight: 300;
        }

        .a-section-blog__body {
            padding: 11px 0;
            background-color: $white;

            &--grey {
                background-color: $carleLightGrey;
            }
        }
    }

    &--patient {
        padding: 55px 0 0;

        h3 {
            margin: 0 0 14px;
        }

        p {
            margin: 0 0 6px;
        }

        .offset-none {
            margin: 0;
        }

        .list-bullets {
            margin: 0 0 23px 29px;
        }
    }

    &--about {
        padding: 55px 0 0;

        .grid-x {
            margin: 0 -40px;
        }

        .cell {
            padding: 0 40px;
        }

        h2 {
            margin: 0 0 39px;
            letter-spacing: .05em;
            line-height: 1.2;
        }

        .a-title-underline {
            padding: 0 0 51px;
        }

        h3 {
            margin: 0 0 37px;
            font-size: 2rem;
        }

        h6 {
            margin: 0;
            font-size: 1rem;
            line-height: 1.6;
            font-weight: 900;
        }

        p {
            margin: 0 0 12px;
            line-height: 1.6;
            font-weight: 300;
        }

        .grid-container {
            padding: 42px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(85,85,89, .5);
            }

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                left: -42px;
                background-color: rgba(85,85,89, .5);
            }
        }

        ul {
            columns: 2;
            line-height: 2.9rem;
            margin-bottom: 25px;
        }
    }


    @media #{$large-desktop} {
        .grid-x {
            margin: 0 -10px;
        }

        .cell {
            padding: 0 10px;
        }
    }

    @media #{$mobile} {
        padding: 0px 0 0;

        .grid-x {
            margin: 0;
        }

        .cell {
            padding: 0;
        }

        h2 {
            margin: 0 0 10px;
            font-size: 1.5rem;
        }

        h3 {
            margin: 0 0 21px;
            letter-spacing: .05em;
            font-size: 1.5rem;
        }

        h5 {
            margin: 0 0 4px;
            font-size: 1.5rem;
            letter-spacing: .05em;
        }

        .a-title-underline {
            padding: 0 0 10px;
        }

        .a-section-blog__group {
            position: relative;

            & ~ .a-section-blog__group {
                margin: 20px 0 0;
                padding: 0px 0 0;

                &:before {
                    display: none;
                }
            }
        }

        .a-section-blog__foot {
            display: none;
        }

        &--questions {
            h3 {
                margin: 0 0 21px;
                letter-spacing: .05em;
                font-size: 1.2rem;
            }
        }

        &--patient {
            padding: 14px 0 0;

            h3 {
                margin: 0 0 9px;
                font-size: 1.5rem;
            }

            p {
                margin: 0 0 12px;
            }

            .offset-none {
                margin: 0;
            }

            .list-bullets {
                margin: 0 0 23px 29px;
            }

            .a-section-blog__group {
                padding: 0 5px;
            }
        }

        &--about {

            ul {
                columns: 1;
            }

            .grid-container {
                padding: 42px 42px 0 42px;
                position: relative;
            }
        }
    }
}

//Section Contact Us

.o-section-contact-us {
    padding: 0;

    h2 {
        margin: 0 0 35px;
        letter-spacing: .035em;
    }

    .a-title-underline {
        padding: 0 0 27px;
    }

    p {
        margin: 0 0 18px;
        letter-spacing: -.005em;
    }

    .a-section-contact-us__head {
        margin: 0 0 0px;
    }

    @media #{$mobile} {
        padding: 0px;

        h2 {
            margin: 0 0 31px;
            font-size: 1.5rem;
        }

        p {
            margin: 0 0 20px;
            line-height: 1.5;

            span {
                display: block;
                font-weight: 900;
            }

            strong {
                color: $linkColor;
            }
        }

        .a-title-underline {
            padding: 0 0 35px;

            &:after {
                height: 4px;
                width: 96px;
            }
        }

        .a-section-contact-us__head {
            margin: 0 0 36px;
            padding: 0 6px;
        }

        .a-section-contact-us__body {
            padding: 0 6px;
        }
    }
}

// Section Meet Team

.o-section-meet-team {
    padding: 0px 0;
    margin-top: -50px;


    .a-btn-inverse {
        padding: 12px 20px;
        text-transform: uppercase;
    }

    .m-section-meet-team__body {
        //padding: 17px 0 74px;
    }

    .m-section-meet-team__inner {
        display: flex;
        padding: 7px 0 0 81px;
    }

    .m-section-meet-team__sidebar {
        flex: 0 0 200px;
        max-width: 200px;
        margin-right: -44px;
    }

    .m-section-meet-team__content {
        flex: 1;

        h2 {
            margin: 0 0 42px;
            font-size: 2rem;
        }

        h6 {
            color: $carleRed;
            font-family: $font-family-avenir;
            font-weight: 900;
            text-transform: uppercase;
        }
    }

    .m-section-meet-team__group {
        padding: 0 60px 0 0;
        margin: 0 0 27px;

        p {
            margin: 0 0 8px;
            letter-spacing: -.005em;
            line-height: 1.4;
        }

        &--alt {
            padding: 0 132px 0 0;
            margin: 0 0 0px;

            p {
                margin: 0 0 17px;
                line-height: 1.35;
            }
        }
    }

    @media #{$large-desktop} {
        .m-section-meet-team__sidebar {
            margin-right: 0px;
        }
    }

    @media #{$mobile} {
        //padding: 40px 0;

        .m-section-meet-team__inner {
            display: block;
            padding: 7px 0 0 0px;
        }

        .m-section-meet-team__sidebar {
            max-width: 100%;
            margin-top: 20px;
        }

        .m-section-meet-team__group {
            padding: 0;
            margin: 0 0 15px;

            &--alt {
                padding: 0;
                margin: 0 0 0px;
            }
        }
    }
}

//Narrow section

.narrow-section {
    li {
        padding: 8px 0;
    }
}

// Section awards

.o-section-awards {
    padding: 15px 0 0px;


    h2 {

    }

    .list-awards {
        a {
            pointer-events: none;
        }
    }

    .a-section-awards__head {
        margin: 0 0 30px;
        text-align: center;
    }

    .a-section-awards__actions {
        text-align: center;
    }

    .a-btn-main {
        position: relative;
        padding: 12px 40px;

        i {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            font-size: 11px;
        }

        &.more {
            i {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}

.o-section-video-thumb {
    display: flex;
    justify-content: center;
    flex-direction: column;    
    > * {
        display: inline-block;
        margin: 0.5rem auto;
    }
}