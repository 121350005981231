input[type=search], input[type=text], input[type=radio].radiofoc {
    &:focus {
        outline: 2px solid $linkColor;
        outline-offset: 4px;
    }
}

input[type=search] {
    -moz-appearance: none; /* older firefox */
    -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
    appearance: none; /* rest */
}

input::placeholder {
    color: inherit;
    opacity: 1;
}

textarea::placeholder {
    color: inherit;
    opacity: 1;
}

input:-webkit-autofill {
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

// Field

.field {
    width: 100%;
    height: 32px;
    border: 1px solid $maintextcolor;
    color: $maintextcolor;
    text-align: center;
    margin: 0;
}

// Radio

.radio {
    display: inline-block;
    width: 100%;

    & [type="radio"] {
        // display: none;
        @extend .show-for-sr;

        & + label {
            margin: 0;
            width: 100%;
            height: 32px;
            position: relative;
            cursor: pointer;
            font-size: 1rem;
            text-align: center;
            border: 1px solid $maintextcolor;
            line-height: 32px;
        }

        &:checked + label {
            border: 1px solid $carleRed;
            color: $carleRed;
        }
    }
}

// Checkbox

.radio-checkbox {
    text-align: left;
    padding-top: 12px;

    & [type="radio"] {
        // display: none;
        @extend .show-for-sr;

        & + label {
            position: relative;
            cursor: pointer;
            padding-left: 15px;
            font-size: 0.875rem;
            line-height: 1;
            font-weight: 700;
            text-transform: uppercase;
            color: $maintextcolor;

            &:before {
                content: '';
                position: absolute;
                left: -8px;
                top: -2px;
                width: 16px;
                height: 16px;
                border-radius: 0;
                background-color: transparent;
                border: 1px solid $maintextcolor;
            }

            &:after {
                content: '';
                position: absolute;
                height: 9px;
                width: 6px;
                top: 0px;
                left: -3px;
                border: 2px solid $carleRed;
                border-left: 0;
                border-top: 0;
                transform: rotate(45deg);
                opacity: 0;
                transition: opacity .3s;
            }
        }
    }

    &--circle {
        [type="radio"] + label:before {
            border-radius: 50%;
        }
    }
}

.radio-checkbox [type="radio"]:checked + label:before,
.radio-checkbox [type="radio"]:focus + label:before {
    border-color: $carleRed;
}

.radio-checkbox [type="radio"]:checked + label:after {
    opacity: 1;
}

.radio-checkbox [type="radio"]:checked + label {
    color: $carleRed;
}


// Checkbox

.checkbox {
    text-align: left;
    padding-top: 12px;

    & [type="checkbox"] {
        // display: none;
        @extend .show-for-sr;

        & + label {
            position: relative;
            cursor: pointer;
            padding-left: 15px;
            font-size: 0.875rem;
            line-height: 1;
            font-weight: 700;
            text-transform: uppercase;
            color: $maintextcolor;

            &:before {
                content: '';
                position: absolute;
                left: -8px;
                top: -2px;
                width: 16px;
                height: 16px;
                border-radius: 0;
                background-color: transparent;
                border: 1px solid $maintextcolor;
            }

            &:after {
                content: '';
                position: absolute;
                height: 9px;
                width: 6px;
                top: 0px;
                left: -3px;
                border: 2px solid $carleRed;
                border-left: 0;
                border-top: 0;
                transform: rotate(45deg);
                opacity: 0;
                transition: opacity .3s;
            }
        }
    }
}

.checkbox [type="checkbox"]:checked + label:before,
.checkbox [type="checkbox"]:focus + label:before {
    border-color: $carleRed;
}

.checkbox [type="checkbox"]:checked + label:after {
    opacity: 1;
}

.checkbox [type="checkbox"]:checked + label {
    color: $carleRed;
}


// Select

.a-select {
    position: relative;
    height: 48px;
    font-weight: 300;
    color: $maintextcolor;

    &.url-selector-wrap:after {
        right: 16px;
        font-size: .9rem;
    }

    select {
        margin: 0;
        width: 100%;
        height: 3rem;
        padding-right: 2rem;
        font-weight: 300;
        color: $maintextcolor;
        background-image: none;
        border: 1px solid $maintextcolor;

        &:focus {
            outline: 2px solid $linkColor;
            outline-offset: 4px;
        }
    }




    select::-ms-expand {
        display: none;
    }

    &:after {
        content: '';
        position: absolute;
        right: 2rem;
        top: 40%;
        border: 2px solid $linkColor;
        width: 14px;
        height: 14px;
        border-left: 0;
        border-top: none;
        transform: rotate(45deg) translateY(-50%);
        z-index: 6;
        pointer-events: none;
    }

    &--border {
        border: 1px solid $maintextcolor;
    }

    &--error {
        select, &:after {
            color: $carleRed;
            border-color: $carleRed;
        }

        option {
            color: $maintextcolor;
        }
    }
}

.autocomplete {
    @extend .m-search;

    &-input {
        @extend .m-search__field;
        
        &:focus {
            outline: 2px solid $linkColor;
            outline-offset: 4px;
        }
        @at-root [data-position=below] #{selector-unify(&, [aria-expanded=true])} {
            border-bottom-color: transparent;
        }

        @at-root [data-position=above] #{selector-unify(&, [aria-expanded=true])} {
            border-top-color: transparent;
            z-index: 2;
        }
    }

    &-result-list {
        margin: 0;
        border: 1px solid $maintextcolor;
        padding: 0;
        box-sizing: border-box;
        max-height: 296px;
        overflow-y: auto;
        background: #fff;
        list-style: none;

        @at-root [data-position=below] & {
            margin-top: -1px;
            border-top-color: transparent;
            padding-bottom: 8px;
        }

        @at-root [data-position=above] & {
            margin-bottom: -1px;
            border-bottom-color: transparent;
            padding-top: 8px;
        }
    }

    &-result {
        cursor: default;
        padding: 12px;

        &:hover, #{selector-unify(&, [aria-selected=true])} {
            background-color: rgba(0,0,0,.06)
        }
    }
}

.m-icon-field{
    position: relative;

    &__icon{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        + input, + select{
            padding-left: 30px;
        }
    }
}